body {
    background: radial-gradient(
        circle, 
        rgba(255, 255, 255, 0.0) 70%, 
        rgba(255, 255, 255, 0.6) 100%
    ), 
    #FF7A00; 

    display: flex; 
    justify-content: center; 
    align-items: center; 

    height: 100vh; 
    margin: 0; 

    font-family: 'Poppins', sans-serif;

    background-attachment: fixed;
    overflow-x: hidden; 
    overflow-y: hidden; 
}

.redirect__container {
    margin: auto;
    width: 100%;

    justify-content: center; 
    align-items: center;

    display: flex;
    flex-direction: column;
    gap: 0.5rem; 

    text-align: center;
}

.download_button {
    padding: 1rem 6rem; 

    border-radius: 2.5rem;
    border-style: none;
    background-color: white;
    color: black; 

    font-size: 2rem; 
    font-family: 'Poppins', sans-serif;
    font-weight: 500;

    box-shadow: 0 0 1rem 0.5rem white;
    transition: transform 0.2s ease, box-shadow 0.2s ease; 
}

h1 {
    color: white;
    font-weight: 600;
}

h2 {
    color: white;
    font-weight: 500;
}

img {
    width: 100%;
    height: auto;
    object-fit: contain; 
    margin: 0 auto;
}

@media (max-width: 480px) {
    body {
        padding: 0.5rem;
    }

    .download_button {
        padding: 1rem 3rem; 
        font-size: 1.5rem;  
    }

    h1 {
        font-size: 2rem; 
    }

    h2 {
        font-size: 1.5rem;
    }

    img {
        max-width: 300px; 
    }
}

@media (max-width: 768px) {
    body {
        padding: 1rem; 
    }

    .download_button {
        padding: 1rem 4.75rem; 
        font-size: 1.8rem; 
    }

    h1 {
        font-size: 1.75rem; 
    }

    h2 {
        font-size: 1.25rem; 
    }

    img {
        max-width: 400px; 
    }
}

@media (min-width: 1024px) {
    body {
        padding: 1.5rem; 
    }

    .download_button {
        padding: 1rem 6rem; 
        font-size: 2rem; 
    }

    h1 {
        font-size: 2rem; 
    }

    h2 {
        font-size: 1.5rem; 
    }

    img {

        max-width: 500px; 
    }
}

/*landscape  mode*/
@media (max-width: 1024px) and (orientation:landscape) {
    body {
        padding: 0.5rem;
        margin: 0;
    }

    .download_button {
        padding: 1rem 6rem; 
        font-size: 2rem; 
    }

    h1 {
        font-size: 2rem; 
    }

    h2 {
        font-size: 1.5rem; 
    }

    img {
        display: none;
    }
}

